import React, {useState} from 'react'

export default function TextForm(props) {

    const handleOnChange = (event) =>{
        setText(event.target.value);
    }    

    const handleUpClick = () =>{
        let newtext = text.toUpperCase();
        setText(newtext);
        props.showAlert('Text Changed in uppercase', 'success');
    }
    
    const handleLwClick = () =>{
        let newtext = text.toLowerCase();
        setText(newtext);
        props.showAlert('Text Changed in lowercase', 'success');
    }
    
    const handleClearClick = () =>{
        setText('');
        props.showAlert('Text clear', 'warning');
    }
    
    const handleCopyClick = () =>{
        navigator.clipboard.writeText(text);
        props.showAlert('Text Copied', 'success');
    }  
    const handleExtraSpace = () =>{
        let newText = text.split(/[ ]+/);
        setText(newText.join(" "));
        props.showAlert('Extra Spaces Removed from string', 'success');
    }
    const handleSentanceCase = () =>{
        let newText = text.toLowerCase().split(". ");
        console.log(newText);
        for (var i = 0; i < newText.length; i++) {
            console.log(newText[i]);
            newText[i] = newText[i].charAt(0).toUpperCase() + newText[i].substring(1);     
        }
        setText(newText.join(". "));
        props.showAlert('String changed with sentance cases', 'success');
    }  
    const handleCapitalizeEach = () =>{
        let newText = text.toLowerCase().split(" ");
        for (var i = 0; i < newText.length; i++) {
            newText[i] = newText[i].charAt(0).toUpperCase() + newText[i].substring(1);     
        }
        setText(newText.join(" "));
        props.showAlert('String changed with Capital each word', 'success');
    }
    const [text, setText] = useState('');
    return (
        <>
            <div className='container'>
                <h1>{props.heading}</h1>
                <div className="mb-3">
                    <textarea className="form-control" value={text} onChange={handleOnChange} style={{backgroundColor : props.mode === 'light' ? 'white' : 'gray    ' }} id="myBox" rows="8"></textarea>
                </div>
                <button disabled={text.length===0} className="btn btn-primary mx-1 my-1" onClick={handleUpClick}>Convert to Upper Case</button> 
                <button disabled={text.length===0} className="btn btn-info mx-1 my-1" onClick={handleLwClick}>Convert to Lower Case</button>
                <button disabled={text.length===0} className="btn btn-danger mx-1 my-1" onClick={handleClearClick}>Clear Text</button>
                <button disabled={text.length===0} className="btn btn-warning mx-1 my-1" onClick={handleCopyClick}>Copy Text</button>
                <button disabled={text.length===0} className="btn btn-warning mx-1 my-1" onClick={handleExtraSpace}>Remove Extra Space</button>
                <button disabled={text.length===0} className="btn btn-success mx-1 my-1" onClick={handleSentanceCase}>Sentance Case.</button>
                <button disabled={text.length===0} className="btn btn-danger mx-1 my-1" onClick={handleCapitalizeEach}>Capitalize Each Word</button>
            </div>
            <div className='container my-3'>
                <h2>Your Text Summery</h2>
                <p>{ text.split(/\s+/).filter((element) => { return element.length !== 0 }).length } Words, and {text.length} Charactors</p>
                <p>{ 0.008 * text.split(' ').filter((element) => { return element.length !== 0 }).length } Minutes to read</p>
                <h2>Preview</h2>
                <p>{text.length>0?text:"Nothing to Preview"}</p>
            </div>
        </>
    )
}
