import React, {useState} from 'react'
import './App.css';
import Alert from './components/Alert';
import Navbar from './components/Navbar';
import About from './components/About';
import TextForm from './components/TextForm';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";


function App() {
  const[mode, setMode] = useState("light");

  const removeClassOnBody = () => {
    document.body.classList.remove('bg-light');
    document.body.classList.remove('bg-dark');
    document.body.classList.remove('bg-success');
    document.body.classList.remove('bg-primary');
    document.body.classList.remove('bg-info');
    document.body.classList.remove('bg-danger');
    document.body.classList.remove('bg-warning');
  }

  const toggleMode = (cls) => {
    removeClassOnBody();
    document.body.classList.add('bg-' + cls);
    if(mode === "dark"){
      setMode("light");
      document.body.style.backgroundColor = "white";
      showAlert('Light Mode Enabled','success');
    }else{
      setMode("dark");
      document.body.style.backgroundColor = "gray";
      showAlert('Dark Mode Enabled','danger');
    }
  }

  const [alert, setAlert] = useState(null);

  const showAlert = (message, type) => {
    setAlert({
      msg:message,
      type:type
    });
    setTimeout(() => {
      setAlert(null);
    }, 1500);
  }


  
  return (
    <>
    <Router>
      <Navbar title="TextUtils" aboutText="About TextUtils" mode={mode} toggleMode={toggleMode} />
      <Alert alert={alert} />
      <Routes>
          <Route exact path="/" element={<TextForm heading="Enter the text for analyze" mode={mode} showAlert={showAlert} /> } />
          <Route exact path="/about" element={<About  mode={mode} />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
